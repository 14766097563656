import { MDBCol, MDBContainer, MDBRow } from "mdbreact";

import { Helmet } from 'react-helmet';
import Layout from '../../components/layout';
import React from 'react';
import SEO from '../../components/seo';

const DEI = () => {
    return (
        <Layout>
          <SEO 
            title="DEI | Genesis"
            description={`DEI`}
            keywords={[`DEI`]} 
          />
          <Helmet>
            <meta http-equiv="refresh" content={`0; URL='/404'`} />
          </Helmet>
        <main>
          <MDBContainer tag="section" id="maincontent">
            <MDBRow center>
              <MDBCol xs="12" md="12" lg="12" className="col-12">
    {/*<iframe frameBorder="0" height="8800" marginHeight="0" marginWidth="0" src="https://docs.google.com/forms/d/e/1FAIpQLSfksgmexgmgoMb7d3-TohcH7yVWy9v7ATZsXj8KBl0PNUYbvw/viewform?embedded=true" width="100%">Loading…</iframe>*/}
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </main>
    </Layout>
    )
  }

export default DEI;